.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--theme-primary-color);

  color: var(--theme-white-color);

  padding: 100px 0;
}

.text {
  color: var(--theme-white-color);
  text-align: center;
}

.icons {
  margin-top: 60px;
}

.links {
  margin-top: 117px;
}

.link:hover {
  color: var(--theme-secondary-color);
}

.declaration {
  margin: 10px 30px;
}

.declaration_text {
  font-size: 8px !important;
  font-style: italic;
  text-align: left;
  color: #fff;
}

.copyright {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 20px;
}

.copyright_text {
  margin-right: 20px;
}
